<template>
  <!-- BEGIN: Display Information -->
  <div class="intro-y box lg:mt-5">
    <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
      <h2 class="font-medium text-base mr-auto">
        Change password
      </h2>
    </div>
    <div class="p-5">
      <div class="grid grid-cols-12 gap-5">
        <div class="col-span-4 xl:col-span-4">
          <div class="mt-2">
            <label :class="{'has-error': errorHas('current_password')}">
              Current password
              <input
                v-model="form.current_password"
                type="password"
                class="input w-full border bg-gray-100"
              >
              <span
                v-if="errorHas('current_password')"
                class="pristine-error text-theme-6 mt-2"
              >
                {{ errorGet('current_password') }}
              </span>
            </label>
          </div>
          <div class="mt-4">
            <label :class="{'has-error': errorHas('new_password')}">
              New password
              <input
                v-model="form.new_password"
                type="password"
                class="input w-full border bg-gray-100"
              >
              <span
                v-if="errorHas('new_password')"
                class="pristine-error text-theme-6 mt-2"
              >
                {{ errorGet('new_password') }}
              </span>
            </label>
          </div>
          <div class="mt-4">
            <label>
              New password confirmation
              <input
                v-model="form.new_password_confirmation"
                type="password"
                class="input w-full border bg-gray-100"
              >
            </label>
          </div>
          <base-button
            :loading="form.loading"
            class="button w-20 bg-theme-1 text-white mt-5"
            @click.prevent="changePassword"
          >
            Update
          </base-button>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Display Information -->
</template>

<script>
    import {defineComponent, reactive} from 'vue';
    import {useStore} from 'vuex';
    import {useErrors} from "@/compositions/useErrors";
    import Swal from '@/plugins/swal';

    export default defineComponent({
        name: "PersonalInformation",
        setup() {
            const store = useStore();
            const errors = useErrors();

            const form = reactive({
                loading: false,
                current_password: null,
                new_password: null,
                new_password_confirmation: null
            });

            function changePassword() {
                errors.errorSet({});
                form.loading = true;
                store.dispatch('profile/updatePassword', form).then(() => {
                    Swal.fire({
                        title: 'Success!',
                        text: 'Password updated successfully!',
                        icon: 'success',
                        confirmButtonText: 'Cool'
                    })
                    form.loading = false;
                }).catch(e => {
                    errors.errorSet(e.errors);
                    form.loading = false;
                })
            }

            return {
                form,
                changePassword,
                ...errors
            }
        },
    })
</script>

<style scoped>

</style>